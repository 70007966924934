
body {
  background: #f0f0f0;
  font-family: sans-serif;
}

.cr {
  width: 250px;
  padding: 16px;
  position: absolute;
  text-align: center;
  color: #f0f0f0;
}

.cr-sticky {
  position: fixed;
}

/* Positions */

.cr-top    { top: 25px; }
.cr-bottom { bottom: 25px; }
.cr-left   { left: -55px; }
.cr-right  { right: -65px; }

/* Rotations */

.cr-top.cr-left,
.cr-bottom.cr-right {
  transform: rotate(-45deg);
}

.cr-top.cr-right,
.cr-bottom.cr-left {
  transform: rotate(45deg);
}

/* Colors */

.cr-white     { background-color: #f0f0f0; color: #444; }
.cr-black     { background-color: #333; }
.cr-grey      { background-color: #999; }
.cr-blue      { background-color: #39d; }
.cr-green     { background-color: #2c7; }
.cr-turquoise { background-color: #1b9; }
.cr-purple    { background-color: #95b; }
.cr-red       { background-color: #e43; }
.cr-orange    { background-color: #e82; }
.cr-yellow    { background-color: #ec0; }

